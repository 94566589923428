<template>
    <!-- 导航 -->
    <page-head title="精英人才库" />
    <!-- 列表 -->
    <player-list :list="playerData" @player-click="playerClick" />
    <!-- 翻页 -->
    <pagination-com v-if="playerData.length" class="pagination" :current-page="query.pageNum" :total-page="totalPage" @changePage="changePage"></pagination-com>
</template>

<script>
import {useRouter, onBeforeRouteLeave} from "vue-router";
import {defineComponent, onMounted, getCurrentInstance, toRefs, reactive, watch, computed} from 'vue';
import { useStore } from "vuex";
export default defineComponent({
    setup() {
        const router = useRouter()
        const { proxy } = getCurrentInstance();
        const store = useStore();
        const state = reactive({
            playerData: {}, // 页面数据
            serverData: {}, // 服务端数据
            totalPage: 0,
            query: {
                pageNum: 1,
                pageSize: 12,
                paging: true,
            },
        });
        watch(() => store.state.identifyData, (val, old) => {
            // console.log(val, old);
            if (!val.type) return false;
            const params = {
                ...val,
                page: state.query.pageNum,
                totalPage: state.totalPage,
                showList: state.playerData,
            }
            proxy.$utils.identifyFn({
                type: val.type,
                data: params,
                changePage: changePage,
                router: playerClick
            })
        })
        /** 获取球员列表 **/
        const getData = async () => {
            const { code,  data: { list, pages } } = await proxy.$server.getEliteList(state.query);
            if (code === 200) {
                state.playerData = list;
                state.totalPage = pages;
            }
        };

        const changePage = (page) => {
            state.query.pageNum = page
            getData()
        }

        const playerClick = (row) => {
            let path = '/talent/details'

            router.push({
                path,
                query: {
                    id: row.id,
                    name: row.name
                }
            })
        }

        const queryParams = computed(() => {
            return store.state.queryParams;
        });

        onMounted(() => {
            if(queryParams.value.page) {
                state.query.pageNum = queryParams.value.page
            }
            getData();
        });

        onBeforeRouteLeave((to, from, next) => {
            console.log(to)
            let params = {
                page: state.query.pageNum
            }
            if(to.name === 'talentDetails') {
                store.commit('setQueryParams', params);
            } else {
                params.page = 1
                store.commit('setQueryParams', params);
            }
            next()
        })


        return {
            ...toRefs(state),
            changePage,
            playerClick,
        }
    },
})
</script>

<style scoped lang="scss">
.pagination {
    margin-left: 590px;
}
</style>